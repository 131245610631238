import { memo } from 'react';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { AppBar as StyledBar } from './styled';

interface SideBarProps {
  open: boolean;
  toggleDrawer: () => void;
}

const SideBar = ({ open, toggleDrawer }: SideBarProps) => {
  return (
    <StyledBar position="absolute" open={open}>
      <Toolbar
        sx={{ pr: '24px' }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Dashboard
        </Typography>
      </Toolbar>
    </StyledBar>
  )
}

export default memo(SideBar);
