import { createBrowserRouter } from "react-router-dom";
import ErrorBoundary from "@app/components/ErrorBoundary";

import Login from "@pages/Login";
import GuestLayout from "@layouts/GuestLayout";
import AuthLayout from "@layouts/AuthLayout";
import { PRIVATE_PAGES, PUBLIC_PAGES } from "./pages";
import NotFoundPage from "@pages/NotFoundPage";
import CreateTour from "@pages/CreateTour";
import CreateWork from "@pages/CreateWork";
import Tours from "@pages/Tours";
import EditTour from "@pages/EditTour";
import Messages from "@pages/Messages";
import Works from "@pages/Works";
import EditWork from "@pages/EditWork";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <AuthLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: PRIVATE_PAGES.MESSAGES,
        element: <Messages />,
      },
      {
        path: PRIVATE_PAGES.TOURS,
        element: <Tours />,
      },
      {
        path: PRIVATE_PAGES.WORKS,
        element: <Works />,
      },
      {
        path: PRIVATE_PAGES.CREATE_WORK,
        element: <CreateWork />,
      },
      {
        path: PRIVATE_PAGES.UPDATE_WORK,
        element: <EditWork />
      },
      {
        path: PRIVATE_PAGES.CREATE_TOURS,
        element: <CreateTour />,
      },
      {
        path: PRIVATE_PAGES.UPDATE_TOUR,
        element: <EditTour />,
      },
    ],
  },
  {
    path: "",
    element: <GuestLayout />,
    children: [
      {
        path: PUBLIC_PAGES.LOGIN,
        element: <Login />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default router;
