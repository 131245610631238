import { useState } from 'react';

import { MenuItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { LIMITS, PARAMS } from '@constants/index';

import { StyledStack, StyledSelect } from './styled';

interface BasicPaginationProps {
  count: number;
  callback: (page: number, limit: number) => void;
}

const initialPage = 1

const BasicPagination = ({ count, callback }: BasicPaginationProps) => {
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(PARAMS.LIMIT);

  return (
    <StyledStack
      spacing={4}
    >
      <Pagination
        count={Math.ceil(count / limit)}
        variant="outlined"
        shape="rounded"
        onChange={(_, newPage) => {
          setPage(newPage)
          callback(newPage, limit)
        }}
      />
      <StyledSelect
        value={limit}
        label=""
        onChange={(e) => {
          const newLimit = +(e?.target?.value as string) || PARAMS.LIMIT;

          setLimit(newLimit)
          callback(initialPage, newLimit)
        }}
      >
        {
            LIMITS.map((everyLimit) => {
              return (
                <MenuItem key={everyLimit} value={everyLimit}>{everyLimit}</MenuItem>
              )
            })
          }
      </StyledSelect>
    </StyledStack>
  );
}

export default BasicPagination
