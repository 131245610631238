import { Button, DialogTitle } from '@mui/material';

import { StyledDialog } from './styled'

export interface AreYouSureDeleteProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const AreYouSureDelete = (props: AreYouSureDeleteProps) => {
  const {
    open,
    onClose,
    onDelete
  } = props;

  return (
    <StyledDialog onClose={onClose} open={open}>
      <DialogTitle>Do you want to delete image ?</DialogTitle>
      <Button
        type="button"
        onClick={onDelete}
      >
        Yes
      </Button>
      <Button
        type="button"
        onClick={() => onClose()}
      >
        No
      </Button>
    </StyledDialog>
  );
}

export default AreYouSureDelete
