import { createApi } from "@reduxjs/toolkit/query/react";
import { buildBaseQuery } from "@app/api";

import { ILoginFormValues, ILoginResponse, IUser } from "../types/index";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: buildBaseQuery(""),
  endpoints: (builder) => ({
    login: builder.mutation<{ data: ILoginResponse }, ILoginFormValues>({
      query: (body) => ({
        url: "/auth/login",
        method: "POST",
        body,
      }),
    }),
    getProfile: builder.query<{ user: IUser }, void>({
      query: () => "/users/profile",
    }),
  }),
});

export const { useLoginMutation, useGetProfileQuery, useLazyGetProfileQuery } = authApi;
