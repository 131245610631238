import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { RenderNewUploadingImagesList } from '@app/components/CreateEditCommon';
import ImageDialog from '@app/components/ImageDialog';
import AreYouSureDelete from '@app/components/AreYouSureDelete';

import { VisuallyHiddenInput, StyledFormHelperText } from './styled';
import { useCreateTourLogic } from './useCreateTourLogic';

const CreateTour = () => {
  const {
    mainImage,
    errors,
    isSuccess,
    previews,
    isImageViewOpen,
    isButtonLoading,
    imageSrcForAction,
    isDeleteModalOpen,
    largeImageMessage,
    updateMainImage,
    onSubmit,
    register,
    handleSubmit,
    onChangeUpload,
    setIsImageViewOpen,
    handleDeleteImage,
    setImageSrcForAction,
    setIsDeleteModalOpen,
  } = useCreateTourLogic();

  return (
    <Stack
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      sx={{
        gap: '10px',
        '.MuiFormHelperText-root': {
          margin: '0px'
        }
      }}
    >
      <Typography variant="h5">
        Create New Tour
      </Typography>
      <TextField
        {...register('titleAm')}
        label="Title Am"
        error={!!errors.titleAm}
        helperText={errors.titleAm ? 'Title is required' : ''}
      />
      <TextField
        {...register('descriptionAm')}
        label="Description Am"
        multiline
        rows={4}
        error={!!errors.descriptionAm}
        helperText={errors.descriptionAm ? 'Description is required' : ''}
      />
      <TextField
        {...register('titleEn')}
        label="Title En"
        error={!!errors.titleEn}
        helperText={errors.titleEn ? 'Title is required' : ''}
      />
      <TextField
        {...register('descriptionEn')}
        label="Description En"
        multiline
        rows={4}
        error={!!errors.descriptionEn}
        helperText={errors.descriptionEn ? 'Description is required' : ''}
      />
      <TextField
        {...register('titleFr')}
        label="Title Fr"
        error={!!errors.titleFr}
        helperText={errors.titleFr ? 'Title is required' : ''}
      />
      <TextField
        {...register('descriptionFr')}
        label="Description Fr"
        multiline
        rows={4}
        error={!!errors.descriptionFr}
        helperText={errors.descriptionFr ? 'Description is required' : ''}
      />

      <RenderNewUploadingImagesList
        mainImage={mainImage}
        newPreviewsList={previews}
        togetherImagesLength={previews.length}
        updateMainImage={updateMainImage}
        onOpenDeleteModal={setIsDeleteModalOpen}
        onOpenViewImage={setIsImageViewOpen}
        setImageSrc={setImageSrcForAction}
      />
      <ImageDialog
        url={imageSrcForAction}
        isOpen={isImageViewOpen}
        onClose={() => setIsImageViewOpen(false)}
      />
      <AreYouSureDelete
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={() => handleDeleteImage(imageSrcForAction)}
      />
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        <p>Upload Pictures</p>
        <VisuallyHiddenInput
          type="file"
          id="files"
          multiple
          name="images"
          accept="image/png, image/jpeg, image/jpg"
          onChange={onChangeUpload}
        />
      </Button>
      {largeImageMessage && <StyledFormHelperText id="messages">{largeImageMessage}</StyledFormHelperText>}
      {errors.images?.message && <StyledFormHelperText id="messages">{errors.images?.message}</StyledFormHelperText>}
      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={isButtonLoading}
      >
        Submit
      </Button>
      <p>{isSuccess}</p>
    </Stack>
  );
};

export default CreateTour;
