import { tourSlice } from "./store/tour.slice";
import {
  tourApi,
  useGetToursQuery,
  useCreateTourMutation,
  useUpdateTourMutation,
  useLazyGetTourByIdQuery,
  useLazyGetToursQuery,
  useDeleteTourMutation
} from "./api/tour.api";

export const { setTours } = tourSlice.actions;

export {
  tourApi,
  tourSlice,
  useGetToursQuery,
  useCreateTourMutation,
  useUpdateTourMutation,
  useLazyGetTourByIdQuery,
  useLazyGetToursQuery,
  useDeleteTourMutation
};
