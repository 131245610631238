import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { StyledLink, StyledListItemText } from './styled';

export const mainListItems = (
  <>
    <StyledLink to="/tours">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <StyledListItemText primary="Tours" />
      </ListItemButton>
    </StyledLink>
    <StyledLink to="/messages">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <StyledListItemText primary="Messages" />
      </ListItemButton>
    </StyledLink>
    <StyledLink to="/works">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <StyledListItemText primary="Works" />
      </ListItemButton>
    </StyledLink>
  </>
);
