import { createApi } from "@reduxjs/toolkit/query/react";
import { buildBaseQuery } from "@app/api";
import { FilterParams } from "types";

import { IWork } from "../types/index";

export const workApi = createApi({
  reducerPath: "workApi",
  baseQuery: buildBaseQuery(""),
  endpoints: (builder) => ({
    getWorks: builder.query<{ works: IWork[]; count: number}, FilterParams>({
      query: (params) => {
        const queryString = Object.entries(params)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');

        return `/works?${queryString}`;
      },
    }),
    getWorkById: builder.query<IWork, number>({
      query: (workId) => `/works/${workId}`,
    }),
    createWork: builder.mutation<void, FormData>({
      query: (body) => ({
        url: "/works",
        method: "POST",
        body,
      }),
    }),
    updateWork: builder.mutation<void, any>({
      query: ({ id, formData }) => ({
        url: `/works/${id}`,
        method: "PATCH",
        body: formData,
      }),
    }),
    deleteWork: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/works/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetWorksQuery,
  useLazyGetWorksQuery,
  useDeleteWorkMutation,
  useCreateWorkMutation,
  useUpdateWorkMutation,
  useLazyGetWorkByIdQuery,
} = workApi;
