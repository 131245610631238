import { TextField } from '@mui/material';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s;
    -webkit-text-fill-color: black !important;
  }
  .MuiFormHelperText-root {
    color: red;
  }
`;

export const StyledInput = styled(TextField)`
`;
