import { useEffect } from "react";
import * as React from 'react';

import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { PRIVATE_PAGES } from "@app/router/pages";
import { useAppDispatch, useAppSelector } from "@app/store";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FilterParams } from "types";
import { PARAMS } from "@constants/index";
import BasicPagination from "@app/components/Pagination";
import { setWorks, useDeleteWorkMutation, useLazyGetWorksQuery } from "@modules/work";

const Works = () => {
  const dispatch = useAppDispatch();
  const worksList = useAppSelector((state) => state.work.works)

  const [getWorks] = useLazyGetWorksQuery()
  const [deleteWork] = useDeleteWorkMutation()
  const [pagesCount, setPagesCount] = React.useState<number>(1);
  const [filters, setFilters] = React.useState<FilterParams>({
    limit: PARAMS.LIMIT,
    offset: PARAMS.OFFSET,
  })

  const handleGetRequest = async () => {
    try {
      const responseWork = await getWorks(filters).unwrap();

      dispatch(setWorks(responseWork.works));
      setPagesCount(responseWork.count || 1)
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteWork = async (id: string) => {
    if (!id) {
      return null
    }

    try {
      await deleteWork({ id: +id })
      handleGetRequest()
    } catch (error) {
      console.log(error);
    }
  }

  const handlePagination = (page: number, limit: number) => {
    const offset = page === 1 ? PARAMS.OFFSET : limit * (page - 1)

    setFilters({ limit, offset })
  }

  useEffect(() => {
    handleGetRequest()
  }, [filters])

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}
    >
      <Link to={PRIVATE_PAGES.CREATE_WORK}>
        <Button variant="contained" color="primary">
          Create Work
        </Button>
      </Link>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Work ID</TableCell>
              <TableCell align="center">TITLE AM</TableCell>
              <TableCell align="center">DESCRIPTION AM</TableCell>
              <TableCell align="center">TITLE En</TableCell>
              <TableCell align="center">DESCRIPTION EN</TableCell>
              <TableCell align="center">TITLE FR</TableCell>
              <TableCell align="center">DESCRIPTION FR</TableCell>
              <TableCell align="center">ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {worksList.map((work) => {
              return (
                <TableRow key={work.id}>
                  <TableCell component="th" scope="row">
                    <Link to={`/update-work/${work.id}`}>
                      {work.id}
                    </Link>
                  </TableCell>
                  <TableCell align="center">{work.titleAm}</TableCell>
                  <TableCell align="center">{work.descriptionAm}</TableCell>
                  <TableCell align="center">{work.titleEn}</TableCell>
                  <TableCell align="center">{work.descriptionEn}</TableCell>
                  <TableCell align="center">{work.titleFr}</TableCell>
                  <TableCell align="center">{work.descriptionFr}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => handleDeleteWork(work.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <BasicPagination count={pagesCount} callback={handlePagination} />
    </Box>
  )
};

export default Works;
