import { Dispatch, SetStateAction } from "react";

import { IImage } from "@app/types/image";
import { getImageUrl } from "@app/helpers/getUploadUrl";

import { ImageWrapper, Image, StyledDeleteIcon, SvgWrapper } from './styled'

interface RenderImagesByBackendDataProps {
  file: IImage;
  mainImage: string;
  imagesLength: number;
  updateMainImage: (url: string) => void;
  setOpenDelete: Dispatch<SetStateAction<boolean>>;
  setOpenViaImage: Dispatch<SetStateAction<boolean>>;
  setFirebaseImageForProcess: Dispatch<SetStateAction<IImage>>;
}

const RenderImagesByBackendData = ({
  file,
  mainImage,
  imagesLength,
  setOpenDelete,
  setOpenViaImage,
  updateMainImage,
  setFirebaseImageForProcess,
}: RenderImagesByBackendDataProps) => {
  return (
    <ImageWrapper>
      <input
        name="main"
        type="radio"
        checked={file.url === mainImage}
        onChange={() => {
          updateMainImage(file.url)
        }}
      />
      {
        imagesLength > 1 && (
        <SvgWrapper
          onClick={() => {
            setOpenDelete(true)
            setFirebaseImageForProcess(file)
          }}
        >
          <StyledDeleteIcon />
        </SvgWrapper>
        )
      }
      <Image
        src={getImageUrl(file.url)}
        alt="tour"
        onClick={() => {
          setOpenViaImage(true)
          setFirebaseImageForProcess(file)
        }}
      />
    </ImageWrapper>
  )
}

export default RenderImagesByBackendData;
