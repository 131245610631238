import { Dispatch, SetStateAction } from 'react';

import { Control, useController } from 'react-hook-form';
import { FormHelperText, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { InputWrapper, StyledInput } from './styled';

interface InputProps {
  type: 'password' | 'text';
  id: string;
  name: string;
  label: string;
  error: string;
  placeholder: string;
  autoComplete: string;
  control: Control<any>;
  showPassword?: boolean;
  setShowPassword?: Dispatch<SetStateAction<boolean>>;
}

const Input = ({
  error,
  control,
  showPassword,
  setShowPassword,
  ...rest
}: InputProps) => {
  const { field } = useController({
    name: rest.name,
    control,
    defaultValue: '',
  });

  return (
    <InputWrapper>
      <StyledInput
        error={!!error}
        onChange={(e) => field.onChange(e)}
        InputProps={{
          endAdornment: setShowPassword ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword && setShowPassword((prev) => !prev)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        {...rest}
      />
      {error && <FormHelperText id={rest.id}>{error}</FormHelperText>}
    </InputWrapper>
  );
};

export default Input;
