import * as yup from "yup";

const LoginFormValidation = yup.object({
  login: yup
    .string()
    .trim("Email should not contain spaces")
    .strict(true)
    .email("The Email address is incorrect, please try again")
    .required("Please enter your Email"),
  password: yup
    .string()
    .trim("Password should not contain spaces")
    .strict(true)
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(32, "Password must be at most 32 characters long"),
});

const defaultValues = {
  email: "",
  password: "",
};

export { defaultValues, LoginFormValidation };
