import { Components } from "@mui/material/styles/components";
import { css } from "@mui/material";
import COLORS from "@app/colors";

const root = css``;

const text = css``;

const outlined = css``;

const containedSuccess = css``;

const MuiButton: Components["MuiButton"] = {
  styleOverrides: {
    root,
    text,
    outlined,
    containedSuccess,
  },
};

export default MuiButton;
