import { ChangeEvent, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateTourMutation, useLazyGetTourByIdQuery } from '@modules/tour';
import { IImage } from '@app/types/image';
import { useRenderFileViewHook } from '@app/hooks/imagesRender';
import { useDeleteImageMutation } from '@modules/images';
import { getImageUrl } from '@app/helpers/getUploadUrl';
import { throwImageFiltering } from '@app/helpers/uploadFileValidation';
import { MESSAGES } from '@app/constant';

import { IUpdateTourFormData, editTourSchema } from './data';

export const useEditTourLogic = () => {
  const [deleteImage] = useDeleteImageMutation()
  const { tourId = '' } = useParams()

  const [imageUrl, setImageUrl] = useState('')
  const [isSuccess, setIsSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [largeImageMessage, setLargeImageMessage] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [actionOnImageString, setActionOnImageString] = useState('');
  const [isViewImageModalOpen, setIsViewImageModalOpen] = useState(false);
  const [firebaseImageForProcess, setFirebaseImageForProcess] = useState<IImage>({} as IImage);

  const getTourData = async () => {
    setIsLoading(true)

    try {
      const tour = await getTourById(+tourId).unwrap()

      if (!tour) {
        alert('Something went wrong.')

        return
      }

      const found = tour?.images?.find((file) => file.isMain)

      reset({
        ...tour,
        mainImage: found?.url || '',
        newUploadingImages: {} as DataTransfer['files']
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  }

  const {
    reset,
    watch,
    register,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm<IUpdateTourFormData>({
    // @ts-ignore
    resolver: yupResolver(editTourSchema),
    mode: 'onSubmit',
    defaultValues: {
      newUploadingImages: {} as FileList,
    }
  });

  const mainImage = watch('mainImage')
  const receivedImagesFromBackend = watch('images')
  const newUploadingImages = watch('newUploadingImages')

  const previews = useRenderFileViewHook(newUploadingImages)

  const [getTourById] = useLazyGetTourByIdQuery();
  const [updateTourById] = useUpdateTourMutation();

  const onSubmit = async (data: IUpdateTourFormData) => {
    setIsLoading(true)

    try {
      setIsSuccess('')

      const { titleAm, descriptionAm, descriptionEn, titleEn, descriptionFr, titleFr } = data
      const imagesList = Object.values(data?.newUploadingImages || {})

      const formData = new FormData();

      titleAm && formData.append('titleAm', titleAm);
      descriptionAm && formData.append('descriptionAm', descriptionAm);
      titleEn && formData.append('titleEn', titleEn);
      descriptionEn && formData.append('descriptionEn', descriptionEn);
      titleFr && formData.append('titleFr', titleFr);
      descriptionFr && formData.append('descriptionFr', descriptionFr);
      mainImage && formData.append('mainImage', mainImage);

      for (let i = 0; i < imagesList.length; i++) {
        formData.append('files[]', imagesList[i])
      }

      await updateTourById({ formData, id: tourId }).unwrap()

      getTourData()
      setIsSuccess('Tour has been successfully updated')
      setLargeImageMessage('')
    } catch (error) {
      console.log('Error: ', error);
      setIsSuccess('Something went wrong')
      setIsLoading(false)
      setLargeImageMessage('')
    }
  };

  const onChangeImageUploadInput = (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setLargeImageMessage('')

      const fileList = event.target?.files as FileList
      const onChangeFiles = throwImageFiltering(fileList)
      const chosenImagesLength = Object.keys(onChangeFiles || {}).length

      const alreadyThereAreLength = (receivedImagesFromBackend.length + (newUploadingImages?.length || 0))

      let message = '';

      if (alreadyThereAreLength >= 5) {
        message = 'You can not upload anymore images.';
        setError('images', { message })

        return null
      }

      if ((chosenImagesLength + alreadyThereAreLength) > 5) {
        const hasLength = receivedImagesFromBackend.length + (newUploadingImages?.length || 0)

        message = `You can upload ${5 - hasLength} ${(5 - hasLength) > 1 ? 'images' : 'image'} `

        setError('images', { message })

        return null
      }

      if (fileList.length !== onChangeFiles.length) {
        setLargeImageMessage(MESSAGES.imageSize)
      }

      if (onChangeFiles && newUploadingImages) {
        const files = Array.from(newUploadingImages);

        // Append the new files to the array
        Object.entries(onChangeFiles).forEach(([_, file]) => {
          const found = files.find((item) => item.name === file.name)

          if (!found) {
            files.push(file);
          }
        });

        // Create a new FileList object
        const mergedFileList = new DataTransfer();

        files.forEach((file) => {
          mergedFileList.items.add(file);
        });

        setValue('newUploadingImages', mergedFileList.files, { shouldValidate: true })

        return
      }

      if (onChangeFiles) {
        setValue('newUploadingImages', onChangeFiles, { shouldValidate: true })
      }
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const handleDeleteImageFromFirebase = async () => {
    try {
      if (!firebaseImageForProcess.id) {
        return
      }

      await deleteImage({ id: firebaseImageForProcess.id })
      await getTourData()
      setIsDeleteModalOpen(false)
      setFirebaseImageForProcess({} as IImage)
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const handleDeleteBase64Image = (bas64: string) => {
    if (!newUploadingImages) {
      return null
    }

    const index = previews.findIndex((item) => item === bas64);

    const files = Array.from(newUploadingImages).filter((_, i) => i !== index);

    const mergedFileList = new DataTransfer();

    files.forEach((file) => {
      mergedFileList.items.add(file);
    });

    setValue('newUploadingImages', mergedFileList.files)
    setIsDeleteModalOpen(false)
  }

  const updateMainImage = (url: string) => {
    setValue('mainImage', url);
  }

  useEffect(() => {
    setImageUrl(getImageUrl(firebaseImageForProcess.url))
  }, [firebaseImageForProcess])

  useEffect(() => {
    if (!tourId) {
      return;
    }

    getTourData()
  }, [])

  return {
    mainImage,
    errors,
    imageUrl,
    previews,
    isLoading,
    isSuccess,
    receivedImagesFromBackend,
    largeImageMessage,
    onSubmit,
    register,
    getTourData,
    setImageUrl,
    handleSubmit,
    isViewImageModalOpen,
    setIsViewImageModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    firebaseImageForProcess,
    setFirebaseImageForProcess,
    onChangeImageUploadInput,
    actionOnImageString,
    setActionOnImageString,
    handleDeleteImageFromFirebase,
    handleDeleteBase64Image,
    updateMainImage,
  }
}
