import { useEffect, useState } from "react";

export const useRenderFileViewHook = (newList: DataTransfer['files'] | undefined) => {
  const [previews, setPreviews] = useState<string[] | []>([]);

  useEffect(() => {
    if (!newList?.length) {
      setPreviews([])

      return
    }

    const promises = Object.values(newList).map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises).then((results: unknown) => {
      if (Array.isArray(results)) {
        setPreviews(results as string[]);
      }
    })
  }, [newList]);

  if (!newList?.length) {
    return [];
  }

  return previews
};
