import { useEffect } from "react";

import { Box, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@app/store";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { setMessages, useDeleteMessageMutation, useLazyGetMessagesQuery } from "@modules/messages";

const Messages = () => {
  const dispatch = useAppDispatch();
  const messagesList = useAppSelector((state) => state.message.messages)

  const [getMessages] = useLazyGetMessagesQuery()
  const [deleteMessage] = useDeleteMessageMutation()

  const handleGetRequest = async () => {
    try {
      const messages = await getMessages().unwrap();

      dispatch(setMessages(messages));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMessage = async (id: string) => {
    if (!id) {
      return null
    }

    try {
      await deleteMessage({ id: +id })
      handleGetRequest()
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetRequest()
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>MESSAGE ID</TableCell>
              <TableCell align="center">NAME</TableCell>
              <TableCell align="center">PHONE</TableCell>
              <TableCell align="center">EMAIL</TableCell>
              <TableCell align="center">MESSAGE</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messagesList.map((message) => {
              return (
                <TableRow key={message.id}>
                  <TableCell component="th" scope="row">
                    {message.id}
                  </TableCell>
                  <TableCell align="center">{message.name}</TableCell>
                  <TableCell align="center">{message.phone}</TableCell>
                  <TableCell align="center">{message.email}</TableCell>
                  <TableCell align="center">{message.message}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => handleDeleteMessage(message.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
};

export default Messages;
