import { createTheme } from "@mui/material";

import MuiCssBaseline from "./components/MuiCssBaseline";
import MuiTextField from "./components/MuiTextField";
import MuiButton from "./components/MuiButton";

const theme = createTheme({
  components: {
    MuiCssBaseline,
    MuiTextField,
    MuiButton,
  },
});

export default theme;
