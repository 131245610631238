import { useEffect } from "react";
import * as React from 'react';

import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { PRIVATE_PAGES } from "@app/router/pages";
import { useAppDispatch, useAppSelector } from "@app/store";
import { setTours, useDeleteTourMutation, useLazyGetToursQuery } from "@modules/tour";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FilterParams } from "types";
import { PARAMS } from "@constants/index";
import BasicPagination from "@app/components/Pagination";

const Tours = () => {
  const dispatch = useAppDispatch();
  const toursList = useAppSelector((state) => state.tour.tours)

  const [getTours] = useLazyGetToursQuery()
  const [deleteTour] = useDeleteTourMutation()
  const [pagesCount, setPagesCount] = React.useState<number>(1);
  const [filters, setFilters] = React.useState<FilterParams>({
    limit: PARAMS.LIMIT,
    offset: PARAMS.OFFSET,
  })

  const handleGetRequest = async () => {
    try {
      const responseTours = await getTours(filters).unwrap();

      dispatch(setTours(responseTours.tours));
      setPagesCount(responseTours.count || 1)
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteTour = async (id: string) => {
    if (!id) {
      return null
    }

    try {
      await deleteTour({ id: +id })
      handleGetRequest()
    } catch (error) {
      console.log(error);
    }
  }

  const handlePagination = (page: number, limit: number) => {
    const offset = page === 1 ? PARAMS.OFFSET : limit * (page - 1)

    setFilters({ limit, offset })
  }

  useEffect(() => {
    handleGetRequest()
  }, [filters])

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}
    >
      <Link to={PRIVATE_PAGES.CREATE_TOURS}>
        <Button variant="contained" color="primary">
          Create Tour
        </Button>
      </Link>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>TOUR ID</TableCell>
              <TableCell align="center">TITLE AM</TableCell>
              <TableCell align="center">DESCRIPTION AM</TableCell>
              <TableCell align="center">TITLE En</TableCell>
              <TableCell align="center">DESCRIPTION EN</TableCell>
              <TableCell align="center">TITLE FR</TableCell>
              <TableCell align="center">DESCRIPTION FR</TableCell>
              <TableCell align="center">ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toursList.map((tour) => {
              return (
                <TableRow key={tour.id}>
                  <TableCell component="th" scope="row">
                    <Link to={`/update-tour/${tour.id}`}>
                      {tour.id}
                    </Link>
                  </TableCell>
                  <TableCell align="center">{tour.titleAm}</TableCell>
                  <TableCell align="center">{tour.descriptionAm}</TableCell>
                  <TableCell align="center">{tour.titleEn}</TableCell>
                  <TableCell align="center">{tour.descriptionEn}</TableCell>
                  <TableCell align="center">{tour.titleFr}</TableCell>
                  <TableCell align="center">{tour.descriptionFr}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => handleDeleteTour(tour.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <BasicPagination count={pagesCount} callback={handlePagination} />
    </Box>
  )
};

export default Tours;
