import { AuthStorageKeys } from "../constants";
import { IAuthStore } from "../types";

export const getAuthInitialState = (): IAuthStore => {
  return {
    accessToken: localStorage.getItem(AuthStorageKeys.ACCESS_TOKEN) || "",
    user: null,
  };
};

export const saveTokenInStorage = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(AuthStorageKeys.ACCESS_TOKEN, accessToken);
  localStorage.setItem(AuthStorageKeys.REFRESH_TOKEN, refreshToken);
};

export const purgeAuthDataFromStorage = () => {
  localStorage.removeItem(AuthStorageKeys.ACCESS_TOKEN);
  localStorage.removeItem(AuthStorageKeys.REFRESH_TOKEN);
};
