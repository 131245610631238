import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import AreYouSureDelete from '@app/components/AreYouSureDelete';
import ImageDialog from '@app/components/ImageDialog';
import { RenderNewUploadingImagesList } from '@app/components/CreateEditCommon';

import { VisuallyHiddenInput, StyledFormHelperText } from './styled';
import RenderImagesByBackendData from '../../app/components/RenderImagesByBackendData';
import { useEditTourLogic } from './useEditTourLogic';

const EditTour = () => {
  const {
    mainImage,
    errors,
    previews,
    imageUrl,
    isLoading,
    isSuccess,
    receivedImagesFromBackend,
    largeImageMessage,
    onSubmit,
    register,
    setImageUrl,
    handleSubmit,
    isViewImageModalOpen,
    setIsViewImageModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    firebaseImageForProcess,
    setFirebaseImageForProcess,
    onChangeImageUploadInput,
    actionOnImageString,
    setActionOnImageString,
    handleDeleteImageFromFirebase,
    handleDeleteBase64Image,
    updateMainImage
  } = useEditTourLogic();

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <Stack
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      sx={{
        gap: '10px',
        '.MuiFormHelperText-root': {
          margin: '0px'
        }
      }}
    >
      <Typography variant="h5">
        Update Tour
      </Typography>
      <TextField
        {...register('titleAm')}
        label="Title Am"
        error={!!errors.titleAm}
        helperText={errors.titleAm ? 'Title is required' : ''}
      />
      <TextField
        {...register('descriptionAm')}
        label="Description Am"
        multiline
        rows={4}
        error={!!errors.descriptionAm}
        helperText={errors.descriptionAm ? 'Description is required' : ''}
      />
      <TextField
        label="Title En"
        error={!!errors.titleEn}
        helperText={errors.titleEn ? 'Title is required' : ''}
        {...register('titleEn')}
      />
      <TextField
        label="Description En"
        multiline
        rows={4}
        error={!!errors.descriptionEn}
        helperText={errors.descriptionEn ? 'Description is required' : ''}
        {...register('descriptionEn')}
      />
      <TextField
        label="Title Fr"
        error={!!errors.titleFr}
        helperText={errors.titleFr ? 'Title is required' : ''}
        {...register('titleFr')}
      />
      <TextField
        label="Description Fr"
        multiline
        rows={4}
        error={!!errors.descriptionFr}
        helperText={errors.descriptionFr ? 'Description is required' : ''}
        {...register('descriptionFr')}
      />
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'flex-start',
        }}
      >
        {
          receivedImagesFromBackend.map((file) => (
            <RenderImagesByBackendData
              key={file.id}
              file={file}
              mainImage={mainImage}
              imagesLength={receivedImagesFromBackend.length}
              updateMainImage={updateMainImage}
              setOpenDelete={setIsDeleteModalOpen}
              setOpenViaImage={setIsViewImageModalOpen}
              setFirebaseImageForProcess={setFirebaseImageForProcess}
            />
          ))
        }
      </Box>

      <RenderNewUploadingImagesList
        mainImage=""
        newPreviewsList={previews}
        updateMainImage={() => {}}
        togetherImagesLength={previews.length}
        setImageSrc={setActionOnImageString}
        onOpenDeleteModal={setIsDeleteModalOpen}
        onOpenViewImage={setIsViewImageModalOpen}
      />
      <AreYouSureDelete
        open={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false)
        }}
        onDelete={() => {
          if (firebaseImageForProcess.id) {
            handleDeleteImageFromFirebase()

            return;
          }

          handleDeleteBase64Image(actionOnImageString)
        }}
      />
      <ImageDialog
        isOpen={isViewImageModalOpen}
        url={imageUrl || actionOnImageString}
        onClose={() => {
          setIsViewImageModalOpen(false)
          setImageUrl('')
        }}
      />

      {largeImageMessage && <StyledFormHelperText id="messages">{largeImageMessage}</StyledFormHelperText>}
      {errors.images?.message && <StyledFormHelperText id="messages">{errors.images?.message}</StyledFormHelperText>}
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        <p>Upload Pictures</p>
        <VisuallyHiddenInput
          type="file"
          id="files"
          multiple
          name="images"
          accept="image/png, image/jpeg, image/jpg"
          onChange={onChangeImageUploadInput}
        />
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={isLoading}
      >
        Submit
      </Button>
      <p>{isSuccess}</p>
    </Stack>
  );
};

export default EditTour;
