import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

import {
  saveTokenInStorage,
  purgeAuthDataFromStorage,
} from "../helpers/authHelpers";
import { IAuthStore, IUser } from "../types";
import { AuthStorageKeys } from "../constants";

const initialState: IAuthStore = {
  user: null,
  accessToken: localStorage.getItem(AuthStorageKeys.ACCESS_TOKEN) || "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<{ accessToken: string; refreshToken: string }>) => {
      saveTokenInStorage(payload.accessToken, payload.refreshToken);

      state.accessToken = payload.accessToken;
    },
    setUser: (state, { payload }: PayloadAction<{ user: IUser }>) => {
      state.user = payload.user;
    },
    logout: (state) => {
      purgeAuthDataFromStorage();
      state.user = null;
      state.accessToken = "";
    },
  },
});

export const { setToken, setUser, logout } = authSlice.actions;
