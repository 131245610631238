import * as yup from 'yup';

export interface ICreateTourFormData {
  titleAm: string;
  descriptionAm: string;
  titleEn: string;
  descriptionEn: string;
  titleFr: string;
  descriptionFr: string;
  images: FileList;
  mainImage: string;
}

const imageListSchema = yup.mixed()
  .test(
    'is-files-length',
    'Upload image is required',
    (value) => Object.keys(value || {}).length !== 0
  )
  .test(
    'is-files-length',
    'You can upload maximum 5 pictures',
    (value) => Object.keys(value || {}).length <= 5
  )

export const createTourSchema = yup.object().shape({
  titleAm: yup.string().required('Title is required.'),
  titleEn: yup.string().required('Title is required.'),
  titleFr: yup.string().required('Title is required.'),
  descriptionAm: yup.string().required('Description is required.'),
  descriptionEn: yup.string().required('Description is required.'),
  descriptionFr: yup.string().required('Description is required.'),
  mainImage: yup.string().required('Please select main image.'),
  images: imageListSchema
});
