export enum PUBLIC_PAGES {
  LOGIN = "/login",
}

export enum PRIVATE_PAGES {
  HOME = "/",
  EMPTY = "/",
  MESSAGES = '/messages',
  DASHBOARD = "dashboard",

  TOURS = '/tours',
  CREATE_TOURS = '/create-tour',
  UPDATE_TOUR = '/update-tour/:tourId',

  WORKS = '/works',
  CREATE_WORK = '/create-work',
  UPDATE_WORK = '/update-work/:workId',
}
