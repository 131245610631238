import { ChangeEvent, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MESSAGES } from '@app/constant';
import { useRenderFileViewHook } from '@app/hooks/imagesRender';
import { throwImageFiltering } from '@app/helpers/uploadFileValidation';
import { useCreateWorkMutation } from '@modules/work';

import { ICreateWorkFormData, createWorkSchema } from './data';

export const useCreateWorkLogic = () => {
  const [createWork] = useCreateWorkMutation()
  const [isSuccess, setIsSuccess] = useState('');
  const [largeImageMessage, setLargeImageMessage] = useState('');
  const [isImageViewOpen, setIsImageViewOpen] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [imageSrcForAction, setImageSrcForAction] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const methods = useForm<ICreateWorkFormData>({
    // @ts-ignore
    resolver: yupResolver(createWorkSchema),
    mode: 'onSubmit',
    defaultValues: {
      titleAm: '',
      descriptionAm: '',
      titleEn: '',
      titleFr: '',
      descriptionEn: '',
      descriptionFr: '',
      mainImage: '0',
      images: {} as FileList
    }
  });

  const {
    reset,
    watch,
    register,
    setValue,
    setError,
    handleSubmit,
    formState: { errors }
  } = methods;

  const images = watch('images')
  const mainImage = watch('mainImage')
  const previews = useRenderFileViewHook(images)

  const onSubmit = async (data: ICreateWorkFormData) => {
    setIsButtonLoading(true)

    try {
      setIsSuccess('')

      const {
        titleEn,
        descriptionEn,
        titleAm,
        descriptionAm,
        titleFr,
        descriptionFr,
      } = data

      const imagesList = Object.values(data?.images || {})

      if (!images.length) {
        console.log('Error: ', Object.values(imagesList || {}));

        return
      }

      const formData = new FormData();

      formData.append('titleEn', titleEn)
      formData.append('descriptionEn', descriptionEn)
      formData.append('titleAm', titleAm)
      formData.append('descriptionAm', descriptionAm)
      formData.append('titleFr', titleFr)
      formData.append('descriptionFr', descriptionFr)
      formData.append('mainImage', mainImage)

      for (let i = 0; i < imagesList.length; i++) {
        formData.append('files[]', imagesList[i])
      }

      await createWork(formData).unwrap()

      reset()
      setIsSuccess(MESSAGES.workHasBeenSuccessfullyCreated)
      setIsButtonLoading(false)
      setLargeImageMessage('')
    } catch (error: any) {
      setIsSuccess(error?.data?.message || MESSAGES.somethingWentWrong)
      setIsButtonLoading(false)
      setLargeImageMessage('')
    }
  };

  const onChangeUpload = (event: ChangeEvent<HTMLInputElement>) => {
    setLargeImageMessage('')

    try {
      const fileList = event.target?.files as FileList;
      const onChangeFiles = throwImageFiltering(fileList)
      const chosenImagesLength = Object.keys(onChangeFiles || {}).length

      if (!onChangeFiles) {
        setError('images', { message: MESSAGES.imageIsRequired })
      }

      if (((images?.length || 0) + chosenImagesLength) > 5) {
        setError('images', { message: MESSAGES.notMoreFiveImage })

        return
      }

      if (fileList.length !== onChangeFiles.length) {
        setLargeImageMessage(MESSAGES.imageSize)
      }

      if (images.length) {
        const files = Array.from(images);

        // Append the new files to the array
        Object.entries(onChangeFiles).forEach(([_, file]) => {
          const found = files.find((item) => item.name === file.name)

          if (!found) {
            files.push(file);
          }
        });

        // Create a new FileList object
        const mergedFileList = new DataTransfer();

        files.forEach((file) => {
          mergedFileList.items.add(file);
        });

        setValue('images', mergedFileList.files)

        return
      }

      setValue('images', onChangeFiles)
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const handleDeleteImage = (bas64: string) => {
    const index = previews.findIndex((item) => item === bas64);

    const files = Array.from(images).filter((_, i) => i !== index);

    const mergedFileList = new DataTransfer();

    files.forEach((file) => {
      mergedFileList.items.add(file);
    });

    setValue('images', mergedFileList.files)
    setIsDeleteModalOpen(false)
  }

  const updateMainImage = (stringedIndex: string) => {
    setValue('mainImage', stringedIndex)
  }

  useEffect(() => {
    if (!mainImage && images.length) {
      setValue('mainImage', '0')
    }
  }, [images, mainImage])

  return {
    mainImage,
    images,
    errors,
    isSuccess,
    previews,
    isButtonLoading,
    isImageViewOpen,
    isDeleteModalOpen,
    imageSrcForAction,
    largeImageMessage,
    updateMainImage,
    onSubmit,
    register,
    handleSubmit,
    onChangeUpload,
    setIsImageViewOpen,
    handleDeleteImage,
    setIsDeleteModalOpen,
    setImageSrcForAction,
  }
}
