import { memo, useState } from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { ILoginFormValues, setToken, setUser, useLazyGetProfileQuery, useLoginMutation } from "@modules/auth";
import { yupResolver } from '@hookform/resolvers/yup';
import Input from "@app/components/input";
import { useAppDispatch } from "@app/store";
import { useNavigate } from "react-router-dom";
import { PRIVATE_PAGES } from "@app/router/pages";

import { LoginFormValidation, defaultValues } from "./helper/validation";
import { FieldsWrapper } from "./styled";

const LoginIndex = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<ILoginFormValues>({
    resolver: yupResolver(LoginFormValidation),
    defaultValues,
  });

  const [loginRequest] = useLoginMutation()
  const [getProfile] = useLazyGetProfileQuery()

  const onSubmit = async (data: ILoginFormValues) => {
    try {
      const {
        data: { accessToken, refreshToken },
      } = await loginRequest(data).unwrap();

      dispatch(setToken({ accessToken, refreshToken }));

      const user = await getProfile().unwrap();

      dispatch(setUser(user));
      navigate(PRIVATE_PAGES.HOME);
    } catch (error: any) {
      if (error?.data?.error === 'INVALID_CREDENTIALS') {
        setError('password', { message: 'incorrect email or password' })
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <FieldsWrapper>
            <Input
              type="text"
              id="email"
              name="login"
              control={control}
              placeholder="Email"
              label="Email Address"
              autoComplete="email"
              error={errors?.login?.message || ''}
            />
            <Input
              id="password"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              control={control}
              placeholder="Password"
              autoComplete="password"
              error={errors?.password?.message || ''}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          </FieldsWrapper>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default memo(LoginIndex);
