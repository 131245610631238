import { createApi } from "@reduxjs/toolkit/query/react";
import { buildBaseQuery } from "@app/api";

export const imageApi = createApi({
  reducerPath: "imageApi",
  baseQuery: buildBaseQuery(""),
  endpoints: (builder) => ({
    deleteImage: builder.mutation<void, {id: number}>({
      query: ({ id }) => ({
        url: `/images/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useDeleteImageMutation } = imageApi;
