import { useEffect } from "react";

import { Navigate, useNavigate, Outlet } from "react-router";
import { useAppDispatch, useAppSelector } from "@app/store";
import { PRIVATE_PAGES } from "@app/router/pages";
import { logout } from "@modules/auth";

import { LayoutContainer } from "@layouts/styled";

const GuestLayout = () => {
  const { accessToken, user } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken && user === null) {
      dispatch(logout());

      return;
    }

    navigate(PRIVATE_PAGES.HOME)
  }, [accessToken, user]);

  if (accessToken && user !== null) {
    return <Navigate to={PRIVATE_PAGES.HOME} />
  }

  return (
    <LayoutContainer>
      <Outlet />
    </LayoutContainer>
  );
};

export default GuestLayout;
