export const throwImageFiltering = (files: FileList) => {
  const filteredFiles = Array.from(files)
    .filter((file) => {
      if (!['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
        return false
      }

      if (file.size <= 10 * 1024 * 1024) {
        return true
      }

      return false
    }); // 10MB in bytes

  const newDataTransfer = new DataTransfer();

  filteredFiles.forEach((file) => {
    newDataTransfer.items.add(file);
  });

  return newDataTransfer.files;
}
