import { createSlice } from "@reduxjs/toolkit";

import { ITourStore } from "../types";

const initialState: ITourStore = {
  tours: [],
};

export const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setTours: (state, { payload }) => {
      state.tours = payload;
    },
  },
});

export const { setTours } = tourSlice.actions;
