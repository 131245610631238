import { Dispatch, SetStateAction } from 'react';

import { useLocation } from 'react-router-dom';
import { PRIVATE_PAGES } from '@app/router/pages';

import { ImageWrapper, Image, StyledDeleteIcon, SvgWrapper } from './styled'

interface RenderImagesByBase64Props {
  src: string;
  index: number;
  mainImage: string;
  togetherImagesLength: number;
  updateMainImage: (index: string) => void;
  onOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  onOpenViewImage: Dispatch<SetStateAction<boolean>>;
  setImageSrc: Dispatch<SetStateAction<string>>;
}

const RenderImagesByBase64 = ({
  mainImage,
  src,
  index,
  togetherImagesLength,
  updateMainImage,
  setImageSrc,
  onOpenViewImage,
  onOpenDeleteModal,
}: RenderImagesByBase64Props) => {
  const { pathname } = useLocation()

  return (
    <ImageWrapper>
      {
        PRIVATE_PAGES.CREATE_TOURS === pathname ||
        PRIVATE_PAGES.CREATE_WORK === pathname
          ? (
            <input
              name="main"
              type="radio"
              checked={`${index}` === mainImage}
              value={`${index}`}
              onChange={() => {
                updateMainImage(`${index}`)
              }}
            />
          ) : null
      }
      {
        togetherImagesLength > 1 && (
          <SvgWrapper
            onClick={() => {
              setImageSrc(src)
              onOpenDeleteModal(true)
            }}
          >
            <StyledDeleteIcon />
          </SvgWrapper>
        )
      }
      <Image
        src={src}
        alt="tour"
        onClick={() => {
          onOpenViewImage(true)
          setImageSrc(src)
        }}
      />
    </ImageWrapper>
  )
}

export default RenderImagesByBase64;
