import { createSlice } from "@reduxjs/toolkit";

import { IWorkStore } from "../types";

const initialState: IWorkStore = {
  works: [],
};

export const workSlice = createSlice({
  name: "work",
  initialState,
  reducers: {
    setWorks: (state, { payload }) => {
      state.works = payload;
    },
  },
});

export const { setWorks } = workSlice.actions;
