import { createSlice } from "@reduxjs/toolkit";

import { IMessageStore } from "../types";

const initialState: IMessageStore = {
  messages: [],
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessages: (state, { payload }) => {
      state.messages = payload;
    },
  },
});

export const { setMessages } = messageSlice.actions;
