import { Dispatch, SetStateAction } from "react";

import { Box, Typography } from "@mui/material";
import RenderImagesByBase64 from "@app/components/RenderImagesByBase64";

import { ImagesWrapper } from './styled';

interface RenderNewUploadingImagesListProps {
  mainImage: string;
  togetherImagesLength: number;
  newPreviewsList: string[] | [];
  updateMainImage: (index: string) => void;
  setImageSrc: Dispatch<SetStateAction<string>>;
  onOpenViewImage: Dispatch<SetStateAction<boolean>>;
  onOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
}

const RenderNewUploadingImagesList = ({
  mainImage,
  newPreviewsList,
  togetherImagesLength,
  ...restActions
}:RenderNewUploadingImagesListProps) => {
  if (!newPreviewsList.length) {
    return null
  }

  return (
    <Box
      sx={{ mb: 2 }}
    >
      <Typography
        variant="h5"
        sx={{ mb: 2 }}
      >
        New images will be uploaded
      </Typography>

      <ImagesWrapper>
        {
          newPreviewsList.map((src, index) => {
            return (
              <RenderImagesByBase64
                key={src}
                src={src}
                index={index}
                mainImage={mainImage}
                togetherImagesLength={togetherImagesLength}
                {...restActions}
              />
            )
          })
        }
      </ImagesWrapper>
    </Box>
  );
}

export default RenderNewUploadingImagesList;
