import { createApi } from "@reduxjs/toolkit/query/react";
import { buildBaseQuery } from "@app/api";
import { FilterParams } from "types";

import { ITour } from "../types/index";

export const tourApi = createApi({
  reducerPath: "tourApi",
  baseQuery: buildBaseQuery(""),
  endpoints: (builder) => ({
    getTours: builder.query<{ tours: ITour[]; count: number}, FilterParams>({
      query: (params) => {
        const queryString = Object.entries(params)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');

        return `/tours?${queryString}`;
      },
    }),
    getTourById: builder.query<ITour, number>({
      query: (tourId) => `/tours/${tourId}`,
    }),
    createTour: builder.mutation<void, FormData>({
      query: (body) => ({
        url: "/tours",
        method: "POST",
        body,
      }),
    }),
    updateTour: builder.mutation<void, any>({
      query: ({ id, formData }) => ({
        url: `/tours/${id}`,
        method: "PATCH",
        body: formData,
      }),
    }),
    deleteTour: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/tours/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetToursQuery,
  useLazyGetToursQuery,
  useDeleteTourMutation,
  useCreateTourMutation,
  useUpdateTourMutation,
  useLazyGetTourByIdQuery,
} = tourApi;
