'use client'

import { Stack, Select, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  margin: '50px 0 30px 0',
  alignItems: 'center',
  gap: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'unset',
}))

export const StyledSelect = styled(Select)(({ theme }) => ({
  padding: '4.5px 15px',
  margin: '0px !important',

  '.MuiSelect-select': {
    padding: 0,
  }
}))
