import { memo } from 'react';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import logo from '@app/assets/images/logo.png';

import { mainListItems } from './listItems';
import { Drawer, StyledImage } from './styled';

interface SideBarProps {
  open: boolean;
  toggleDrawer: () => void;
}

const SideBar = ({ open, toggleDrawer }: SideBarProps) => {
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [1],
        }}
      >
        <StyledImage src={logo} alt="Armenian Villages" onClick={toggleDrawer} />
      </Toolbar>
      <Divider />
      <List component="nav">
        {mainListItems}
        <Divider sx={{ my: 1 }} />
      </List>
    </Drawer>
  )
}

export default memo(SideBar);
