import React, { useEffect } from "react";

import { PUBLIC_PAGES } from "@app/router/pages";
import { Navigate, useNavigate, useRouteError } from "react-router-dom";
import { useAppDispatch } from "@app/store";
import { logout } from "@modules/auth";

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const error = useRouteError();
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      dispatch(logout());
      navigate(PUBLIC_PAGES.LOGIN);
    }
  }, [])

  if (error) {
    <Navigate to={PUBLIC_PAGES.LOGIN} />
  }

  return <div>{children}</div>;
}

export default ErrorBoundary;
