import { configureStore } from "@reduxjs/toolkit";
import { authApi, authSlice } from "@modules/auth";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { tourApi, tourSlice } from "@modules/tour";
import { workApi, workSlice } from "@modules/work";
import { imageApi } from "@modules/images";
import { messageApi, messageSlice } from "@modules/messages";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    tour: tourSlice.reducer,
    work: workSlice.reducer,
    message: messageSlice.reducer,
    [imageApi.reducerPath]: imageApi.reducer,
    [tourApi.reducerPath]: tourApi.reducer,
    [workApi.reducerPath]: workApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      imageApi.middleware,
      authApi.middleware,
      tourApi.middleware,
      workApi.middleware,
      messageApi.middleware
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
