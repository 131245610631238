import { useEffect } from "react";

import { Outlet, Navigate, useNavigate, useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "@app/store";
import { logout, setUser, useGetProfileQuery } from "@modules/auth";
import { PRIVATE_PAGES, PUBLIC_PAGES } from "@app/router/pages";
import { Dashboard } from "@modules/dashboard";

import { LayoutContainer } from "@layouts/styled";

const AuthLayout = () => {
  const { accessToken, user } = useAppSelector(({ auth }) => auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { data: userInfoData, isError } = useGetProfileQuery();

  useEffect(() => {
    if (isError) {
      dispatch(logout());
      navigate(PUBLIC_PAGES.LOGIN)

      return;
    }

    if (!userInfoData) {
      return;
    }

    dispatch(setUser(userInfoData));
  }, [dispatch, user, isError, userInfoData]);

  useEffect(() => {
    if (pathname === PRIVATE_PAGES.HOME) {
      navigate(PRIVATE_PAGES.TOURS)
    }
  }, [pathname]);

  if (!accessToken && user) {
    return <Navigate to={PUBLIC_PAGES.LOGIN} />;
  }

  return (
    <LayoutContainer>
      <Dashboard>
        <Outlet />
      </Dashboard>
    </LayoutContainer>
  );
};

export default AuthLayout;
