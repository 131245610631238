import { createApi } from "@reduxjs/toolkit/query/react";
import { buildBaseQuery } from "@app/api";

import { IMessage } from "../types/index";

export const messageApi = createApi({
  reducerPath: "messageApi",
  baseQuery: buildBaseQuery(""),
  endpoints: (builder) => ({
    getMessages: builder.query<{ messages: IMessage[] }, void>({
      query: () => "/contact-us",
    }),
    deleteMessage: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/contact-us/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetMessagesQuery,
  useDeleteMessageMutation
} = messageApi;
