import React, { useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Container, createTheme, ThemeProvider, Toolbar } from '@mui/material';

import SideBar from './components/SideBar';
import { AppBar } from './components';

const defaultTheme = createTheme();

const Dashboard = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar open={open} toggleDrawer={toggleDrawer} />
        <SideBar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, ml: 1 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;
