import { IImage } from '@app/types/image';
import * as yup from 'yup';

export interface IUpdateWorkFormData {
  titleAm?: string;
  descriptionAm?: string;
  titleEn?: string;
  descriptionEn?: string;
  titleFr?: string;
  descriptionFr?: string;
  images: IImage[];
  mainImage: string;
  newUploadingImages?: DataTransfer['files'];
}

export const editWorkSchema = yup.object().shape({
  titleAm: yup.string().required('Title is required'),
  descriptionAm: yup.string().required('Description is required'),
  titleEn: yup.string().required('Title is required'),
  descriptionEn: yup.string().required('Description is required'),
  titleFr: yup.string().required('Title is required'),
  descriptionFr: yup.string().required('Description is required'),
  mainImage: yup.string().required('Please select main image.'),
});
