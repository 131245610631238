import { workSlice } from "./store/work.slice";
import {
  workApi,
  useGetWorksQuery,
  useCreateWorkMutation,
  useUpdateWorkMutation,
  useLazyGetWorkByIdQuery,
  useLazyGetWorksQuery,
  useDeleteWorkMutation
} from "./api/work.api";

export const { setWorks } = workSlice.actions;

export {
  workApi,
  workSlice,
  useGetWorksQuery,
  useCreateWorkMutation,
  useUpdateWorkMutation,
  useLazyGetWorkByIdQuery,
  useLazyGetWorksQuery,
  useDeleteWorkMutation
};
