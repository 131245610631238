import { Components } from "@mui/material/styles/components";
import { css } from "@mui/material";

const root = css`
  width: 100%;
`;

const MuiTextField: Components["MuiTextField"] = {
  styleOverrides: {
    root,
  },
};

export default MuiTextField;
