import styled from 'styled-components';
import { FormHelperText, Typography } from '@mui/material';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const StyledFormHelperText = styled(FormHelperText)({
  color: 'red !important'
});

export const ImageName = styled(Typography)({
  fontSize: 11,
});
