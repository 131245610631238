import { ListItemText, styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Link } from 'react-router-dom';

import { drawerWidth } from '../../helper';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

export const StyledLink = styled(Link)`
  color: #222;
  text-decoration: none;
`

export const StyledListItemText = styled(ListItemText)`
  color: #222;
`

export const StyledImage = styled('img')`
  width: 80px;
  cursor: pointer;
  margin: 0 auto;
`
