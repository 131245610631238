import { Dialog } from '@mui/material';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: relative;
    background: white;
    padding: 35px;

    svg {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
`;
