import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { RootState } from "@app/store";

export const buildBaseQuery = (prefix = "", params?: FetchBaseQueryArgs) => {
  const customUrl = process.env.REACT_APP_API_BASE_URL

  return fetchBaseQuery({
    baseUrl: `${customUrl}/${prefix}`,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      if (state.auth.accessToken) {
        headers.set("Authorization", `Bearer ${state.auth.accessToken}`);
      }

      return headers;
    },
    ...params,
  });
};
