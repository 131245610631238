import CloseIcon from '@mui/icons-material/Close';

import { StyledDialog } from './styled'

export interface SimpleDialogProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
}

const ImageDialog = (props: SimpleDialogProps) => {
  const { onClose, isOpen, url } = props;

  return (
    <StyledDialog onClose={onClose} open={isOpen}>
      <CloseIcon onClick={onClose} />
      {url ? <img src={url} alt="tours" /> : <div>Loading...</div>}
    </StyledDialog>
  );
}

export default ImageDialog
