import { Box } from '@mui/material';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';

export const ImageWrapper = styled(Box)`
  position: relative;
  padding: 5px;
  border: 1px solid #222;

  input {
    position: absolute;
    cursor: pointer;
    top: 10px;
    left: 10px;
  }

  // this style must be here
  svg {
    fill: white;
  }
`;

export const SvgWrapper = styled(DeleteIcon)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  fill: white;
  cursor: pointer;
  background: rgb(0, 0, 0, .5);
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
`;

export const Image = styled.img`
  cursor: zoom-in;
  width: 150px;
  height: 150px;
  object-fit: cover;
`;
